class ZIDXAccountPrivacyPolicy implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountPrivacyPolicyContainer";
    }
    getMatchURL(){
        return "/privacy-policy/";
    }
    render(){
        // console.log("privacy policy");
    }
}
